<template>
  <div class="container">
    <el-tabs v-if="dataList.length" v-model="activeName" class="tabs">
      <el-tab-pane v-for="(formData, i) in dataList" :key="i" :label="formData.title + i" :name="'' + i">
        <InventoryTem :formData="formData" :pdfDownload="false" />
      </el-tab-pane>
    </el-tabs>
    <el-empty v-else description="暂无数据" />
  </div>
</template>

<script>
import InventoryTem from '@/components/Template/inventory.vue'
export default {
  components: { InventoryTem },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    dictId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      dataList: [],
      activeName: ''
    }
  },
  watch: {
    data: {
      handler(val) {
        if (val.length) {
          this.dataList = val
          this.activeName = '0'
        }
      }
    }
  }
}
</script>
